import './App.css';
import { useState } from 'react';
import html2canvas from 'html2canvas';
//import TypeWriter from "./Typewriter";
import Typewriter from 'typewriter-effect';

function App() {
//const words = ['fernández', "vargas", "rodriguez", "hernández", "delgado"];

  const [linea1, setLinea1] = useState('');
  const [linea2, setLinea2] = useState('');
  const [linea3, setLinea3] = useState('');
  const [fontSize, setFontSize] = useState('32');
  const [classGenerate, setClassGenerate] = useState('');
  const [classDisabled, setClassDisabled] = useState(true);

  const onClickExportar = (event) => {
    let htmltag = document.querySelector("#generate-image-download");
    
    html2canvas(htmltag, {
      width: htmltag.width,
      height: htmltag.height
    }).then(canvas => {
      var img = canvas.toDataURL("image/png");
      var link= document.createElement('a');
      let str = document.querySelector("#text").value.toLowerCase().trim();
      let name=   str.replace(/\s/g, "");
      link.download = name + '-fb-profile.png';
      link.href = img;
      link.click();
    });
  }
  const onClickGenerar = (event) => {
    setClassGenerate('generando-texto');
    setClassDisabled(true)
    setFontSize('32')
    
    setTimeout(() => {
      setClassGenerate('');
      let str  = document.querySelector("#text").value.toLowerCase().trim();
      let a =   str.substr(0, str.length - 2);
      let b =   str.substr(str.length - 2,1);
      let c =   str.substr(str.length - 1,1);

      setLinea1(a);
      setLinea2(b);
      setLinea3(c);
      
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: document.querySelector("#btn-descargar").offsetTop
      });
      setClassDisabled(false)
      if(str.length<10){        
        if(str.length>8){
          setFontSize('34')
        }else{
          setFontSize('38')
        }        
      }
      console.log(str, str.length, fontSize, '1000');

    }, 3000);
    
  }
    //fern&aacute;nd<span className="color-white">e</span>z
    const aTypeWriter = [
        'fernánd<span class="color-white">e</span>z', 
        'varg<span class="color-white">a</span>s', 
        'rodrigu<span class="color-white">e</span>z', 
        'hernánd<span class="color-white">e</span>z', 
        'delga<span class="color-white">d</span>o'];
    //<h3 className='linea1 color-yellow stroke-black-3'>{linea1}<span className='color-white'>{linea2}</span>{linea3}</h3><br />

    //<h1 id="typewriter" className='color-yellow stroke-black-10'><TypeWriter data={typeWriter}></TypeWriter></h1>
    
  return (
    <div className="App">
      <header>        
        <h1 id="typewriter" className='color-yellow stroke-black-9'>
          
        <Typewriter
  options={{
    strings: aTypeWriter,
    autoStart: true,
    loop: true,
    cursor:null,
    delay:300,
    deleteSpeed:200
  }}
/>

        </h1>
        <p><strong>Celebremos juntos los 50 años de cumplir sueños en familia</strong></p>
      </header>
      <div className='app-body'>
        <div className="columns cont-generate">
          <div className='left'>
          <h2 className='color-yellow stroke-black-10'>¡Creá tu propia versión!</h2>
          </div>
          <div className="right">
            <div className='form'>
                <p><strong>Agreg&aacute; tu apellido:</strong></p>
                <input id="text" type="text" placeholder='Escribí tu apellido acá' />
                <button onClick={onClickGenerar}  className={classGenerate}>
                    <span>
                        Generando <i class="fa fa-spinner fa-spin"></i>
                    </span>
                  <span>¡Generar ahora!</span>
                </button>
            </div>
            
          </div>
        </div>     

      <div className='cont-meme columns'>        
        <div className='meme left' id='meme'>
          <img className='imgid' src={"./img/phone-perfil.png"} alt="Plantilla de Meme" />
        </div>
        <div className='right'>
          <div class="cont-meme-down">
            <div id='generate-image-download'>
              <h3 className={`linea1 color-yellow stroke-black-print fs-${fontSize}`} >{linea1}<span className='color-white'>{linea2}</span>{linea3}</h3>
            </div>
            <div id='generate-image-preview'>
              <h3 className={`linea1 color-yellow stroke-black-print fs-${fontSize}`} >{linea1}<span className='color-white'>{linea2}</span>{linea3}</h3>
            </div>
        </div>
          <h3 className="stroke-black-1">Descargá tu foto y usala como tu foto de perfil y quedá participando en el sorteo de una pantalla de 65 pulgadas.</h3>
          <button id="btn-descargar" disabled={classDisabled} onClick={onClickExportar}>Descargar foto</button>
        </div>
      </div>
        <div className="thanks">
        <h3 className='stroke-black-5'>¡ Gracias por ser parte de la familia monge!</h3>
          </div>
      </div>
      <footer>
        <div className='columns'>
          <div className="left">Importadora Monge 2024 - Todos los derechos reservados</div>
          <div className="right">
            <ul className="socials">
              <li><a className="social fb" href="https://www.facebook.com/TiendaMonge"> </a></li>
              <li><a className="social instagram" href="https://www.instagram.com/tiendamonge/"> </a></li>
              <li><a className="social tiktok" href="https://www.tiktok.com/@tiendamongecr?_t=8lFsSR5ay9O&_r=1"> </a></li>
            </ul>
          </div>
        </div>
      </footer>
    </div>

    
  );
}

export default App;
